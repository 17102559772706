import Link from "next/link";
import { ThemeProvider } from "next-themes";
import { PrismicLink, PrismicProvider } from "@prismicio/react";
import { PrismicPreview } from "@prismicio/next";
import { Analytics } from "@vercel/analytics/react";
import { repositoryName } from "src/prismicio";
import * as prismicH from "@prismicio/helpers";
import "src/styles/globals.css";
import "@upstash/claps/style.css";
import { Heading } from "src/components/Heading";
import { Toaster } from "react-hot-toast";
import { DefaultSeo, NextSeo } from "next-seo";
import Head from "next/head";
import { getExcerpt } from "src/utils/getExcerpt";

const richTextComponents = {
  heading1: ({ children }) => (
    <Heading as="h2" size="3xl" className="mb-7 mt-12 first:mt-0 last:mb-0">
      {children}
    </Heading>
  ),
  heading2: ({ children }) => (
    <Heading as="h3" size="2xl" className="mb-7 last:mb-0">
      {children}
    </Heading>
  ),
  heading3: ({ children }) => (
    <Heading as="h4" size="xl" className="mb-7 last:mb-0">
      {children}
    </Heading>
  ),
  paragraph: ({ children }) => <p className="mb-7 last:mb-0">{children}</p>,
  oList: ({ children }) => (
    <ol className="mb-7 pl-4 last:mb-0 md:pl-6">{children}</ol>
  ),
  oListItem: ({ children }) => (
    <li className="mb-1 list-decimal pl-1 last:mb-0 md:pl-2">{children}</li>
  ),
  list: ({ children }) => (
    <ul className="mb-7 pl-4 last:mb-0 md:pl-6">{children}</ul>
  ),
  listItem: ({ children }) => (
    <li className="mb-1 list-disc pl-1 last:mb-0 md:pl-2">{children}</li>
  ),
  preformatted: ({ children }) => (
    <pre className="mb-7 rounded bg-slate-100 p-4 text-sm last:mb-0 md:p-8 md:text-lg">
      <code>{children}</code>
    </pre>
  ),
  strong: ({ children }) => (
    <strong className="font-semibold">{children}</strong>
  ),
  hyperlink: ({ children, node }) => (
    <PrismicLink
      field={node.data}
      className="underline decoration-1 underline-offset-2"
    >
      {children}
    </PrismicLink>
  ),
};

export default function App({ Component, pageProps }) {
  const isArticle = pageProps?.article || false;
  const article = pageProps?.article || null;
  return (
    <PrismicProvider
      internalLinkComponent={Link}
      richTextComponents={richTextComponents}
    >
      <PrismicPreview repositoryName={repositoryName}>
        <ThemeProvider attribute="class">
          {isArticle ? (
            <NextSeo
              title={prismicH.asText(article.data.title)}
              description={getExcerpt(article.data.slices)}
              canonical="https://blog.arielfranco.dev"
              additionalMetaTags={[
                {
                  property: "keywords",
                  content: article.tags.join(", ")
                }
              ]}
              twitter={{
                handle: "@arielff33",
                site: "@arielff33",
                cardType: "summary_large_image",
              }}
              openGraph={{
                title: prismicH.asText(article.data.title),
                description: getExcerpt(article.data.slices),
                images: [
                  {
                    url: article.data.featuredImage.url,
                    width: 800,
                    height: 600,
                    alt: prismicH.asText(article.data.title),
                  },
                ],
              }}
            />
          ) : (
            <DefaultSeo
              openGraph={{
                type: "website",
                locale: "pt_BR",
                url: "https://blog.arieldev.com/",
                description: "Blog do Ariel Franco, desenvolvedor web e mobile, apaixonado por tecnologia e programação. Aqui você encontra artigos sobre desenvolvimento web e mobile, além de tutoriais e dicas para desenvolvedores.",
                siteName: "Blog do Ariel",
                images: [
                  {
                    url: "https://images.prismic.io/blog-ariel/87c2c09e-7061-4662-82ec-c29882148f51_1661861746833.jfif?auto=compress%2Cformat&rect=0%2C0%2C337%2C337&w=1200&fit=max",
                    alt: "Blog do Ariel",
                    width: 1200,
                    height: 1200,
                  },
                ],
              }}
              twitter={{
                handle: "@arielff33",
                site: "@arielff33",
                cardType: "summary_large_image",
              }}
            />
          )}
          <Toaster />
          <Component {...pageProps} />
          <Analytics />
        </ThemeProvider>
      </PrismicPreview>
    </PrismicProvider>
  );
}
